/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import tksuitApis from '../services';
import modalReducer from './feature/Modal/modalSlice';
import alertReducer from './feature/Alert/alertSlice';
import authReducer from './feature/Auth/authSlice';
import uploadImgSliceReducer from './feature/UploadImg/uploadImgSlice';
import uploadFileSliceReducer from './feature/UploadFile/uploadFileSlice';
import uploadFilesSliceReducer from './feature/UploadFiles/uploadFilesSlice';
import permissionReducer from './feature/permissionSlice';
import collabFiltersReducer from './feature/collabFiltersSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    alert: alertReducer,
    permission: permissionReducer,
    uploadImg: uploadImgSliceReducer,
    uploadFile: uploadFileSliceReducer,
    uploadFiles: uploadFilesSliceReducer,
    collabFilters: collabFiltersReducer,
    [tksuitApis.reducerPath]: tksuitApis.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    tksuitApis.middleware,
  ]),
});

setupListeners(store.dispatch);

export default store;
