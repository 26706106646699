import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const MyInfoDetail = <Loadable callback={() => import('./MyInfoDetail')} />;
const PreviousContracts = <Loadable callback={() => import('./PreviousContracts')} />;
const FutureContracts = <Loadable callback={() => import('./FutureContracts')} />;

const viewMyInfo = (
  <>
    <Route index element={MyInfoDetail} />
    <Route
      path="contract-history"
      element={PreviousContracts}
    />
    <Route
      path="contract-schedule-changes"
      element={FutureContracts}
    />
  </>
);

export default viewMyInfo;
