import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';
import RequireStateLocation from '../../../required/RequireStateLocation';

const CollaboratorDetail = <Loadable callback={() => import('./CollaboratorDetail')} />;
const NewCollaborator = <Loadable callback={() => import('./NewCollaborator')} />;
const PanelCollaborator = <Loadable callback={() => import('./PanelCollaborator')} />;
const PreviousContracts = <Loadable callback={() => import('./CollaboratorDetail/PreviousContracts')} />;
const FutureContracts = <Loadable callback={() => import('./CollaboratorDetail/FutureContracts')} />;

const viewCollaborators = (
  <>
    <Route index element={PanelCollaborator} />
    <Route path="new-collaborator" element={NewCollaborator} />
    <Route
      path="collaborator-detail/:id"
      element={
        <RequireStateLocation component={CollaboratorDetail} />
      }
    />
    <Route
      path="collaborator-detail/:id/contract-history"
      element={
        <RequireStateLocation component={PreviousContracts} />
      }
    />
    <Route
      path="collaborator-detail/:id/contract-schedule-changes"
      element={
        <RequireStateLocation component={FutureContracts} />
      }
    />
  </>
);

export default viewCollaborators;
