import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const PanelReports = <Loadable callback={() => import('./ReportsPanel')} />;

const viewReports = (
  <Route index element={PanelReports} />
);

export default viewReports;
