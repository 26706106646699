import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const EntitiesPanel = <Loadable callback={() => import('./EntitiesPanel')} />;

const entitiesViews = (
  <Route index element={EntitiesPanel} />
);

export default entitiesViews;
