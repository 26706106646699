import {
  format,
  parseISO,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isEqual,
  getDay,
  addDays,
  getDate as getTodayDay,
  isValid,
} from 'date-fns';

export const getDistanceToNow = (date) => {
  if (typeof date === 'string' && date) {
    try {
      const time = formatDistanceToNowStrict(new Date(date), {
        unit: 'year',
        roundingMethod: 'ceil',
      });
      return time;
    } catch (error) {
      return 'Invalid date';
    }
  }
  return 'Invalid date';
};

export const getCurrentDate = (type) => {
  if (typeof type === 'string' && type) {
    try {
      return format(new Date(), type);
    } catch (error) {
      return 'Invalid date';
    }
  }
  return 'Invalid date';
};

export const isSameOrAfter = (date) => {
  if (typeof date !== 'string' || !date) return 'Invalid date';
  const parseIsoDate = parseISO(date);
  if (!isValid(parseIsoDate)) return 'Invalid date';
  const todayDate = new Date();
  const parseDate = new Date(parseIsoDate);
  return isEqual(todayDate, parseDate) || isAfter(parseDate, todayDate);
};

export const getDateOfISOString = (date, type) => (date && type ? format(new Date(date), type) : '');

export const getDate = (date, type) => (date && type ? format(new Date(parseISO(date)), type) : '');

export const isToday = (date) => getTodayDay(new Date(parseISO(date))) === getTodayDay(new Date());

export const isBeforeDate = (date) => {
  const todayDate = new Date();
  const parseDate = new Date(parseISO(date));
  return isBefore(parseDate, todayDate);
};

export const isFutureDate = (date) => {
  const todayDate = new Date();
  const futureDate = new Date(parseISO(date));
  return isAfter(futureDate, todayDate);
};

export const futureStartDate = (date) => getDate(date, 'PPP').split(',')[0];

export const orderEventsDates = (a, b) => (
  getDate(a?.start?.dateTime || a?.start?.date, 'd') - getDate(b?.start?.dateTime || b?.start?.date, 'd')
);

export const isMonthDate = (date, currentDate) => {
  const currentDateISOS = currentDate.toISOString();
  const currentMonth = getDate(currentDateISOS, 'LLLL');
  const dateMonth = getDate(date, 'LLLL');
  return dateMonth === currentMonth;
};

export const isWeekday = (date) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

export const getDateFormat = (date) => {
  if (date) {
    return new Date(date).toISOString();
  }
  return '';
};

export const getDates = (startDate, stopDate) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};
