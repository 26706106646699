import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import useModules from '../../hooks/useModules';
import NoMatch from '../screens/NoMatch';
import SkeletonTable from '../components/basic/SkeletonTable';

const RequirePermission = ({ component }) => {
  const location = useLocation();
  const { modules, isLoading } = useModules();

  if (isLoading) {
    return (
      <div className="w-full pt-2 px-8">
        <SkeletonTable nCols={1} nRows={5} />
      </div>
    );
  }
  const pathname = location.pathname.split('/');
  const navItem = modules.find((elem) => elem?.route === `/${pathname[1]}`);
  if (navItem) {
    if (pathname.length <= 2) {
      return component;
    }

    const isPermittedAll = navItem?.ALL || navItem?.SUPERADMIN;
    if (isPermittedAll) {
      return component;
    }

    const componentType = pathname[2].split('-')[0];
    const isPermittedSee = navItem?.LIST && componentType !== 'edit' && componentType !== 'new';
    if (isPermittedSee) {
      return component;
    }

    const isPermittedRegisterVacationsOrLeaves = navItem?.COLLABORATOR
    || navItem?.APPROVER_HEAD
    || navItem?.APPROVER_NO_HEAD;
    if (isPermittedRegisterVacationsOrLeaves) {
      return component;
    }
  }

  return <NoMatch />;
};

RequirePermission.propTypes = {
  component: PropTypes.node.isRequired,
};

export default RequirePermission;
