/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
};

export const uploadFilesSlice = createSlice({
  name: 'uploadFiles',
  initialState,
  reducers: {
    getFiles: (state, { payload: file }) => {
      state.files.push(file);
    },
    clearFiles: (state) => {
      state.files = [];
    },
  },
});

export const { getFiles, clearFiles } = uploadFilesSlice.actions;

export default uploadFilesSlice.reducer;
