import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import LinearIndeterminate from '../basic/LinearIndeterminate';

/* loadable component return a component Suspense with a lazy component */
const Loadable = ({ callback, name = '', childProps }) => {
  const Component = lazy(callback);

  return (
    <Suspense
      fallback={(
        <LinearIndeterminate />
    )}
      name={name}
    >
      <Component {...childProps} />
    </Suspense>
  );
};

Loadable.propTypes = {
  callback: PropTypes.func.isRequired,
  name: PropTypes.string,
  childProps: PropTypes.shape({
    param: PropTypes.string,
  }),
};

Loadable.defaultProps = {
  name: '',
  childProps: {},
};

export default Loadable;
