import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const CECOsPanel = <Loadable callback={() => import('./CECOsPanel')} />;

const cecosViews = (
  <Route index element={CECOsPanel} />
);

export default cecosViews;
