import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const TribesPanel = <Loadable callback={() => import('./TribesPanel')} />;

const tribesViews = (
  <Route index element={TribesPanel} />
);

export default tribesViews;
