const urls = [
  {
    url: 'collaborators',
    tagName: 'Collaborators',
    individualInvalidatesTags: ['CollaboratorHistory'],
  },
  {
    url: 'collaborator-child',
    tagName: 'CollaboratorChild',
    individualInvalidatesTags: ['CollaboratorChildHistory'],
  },
  {
    url: 'collaborator-benefits',
    tagName: 'CollaboratorBenefits',
    individualInvalidatesTags: ['CollaboratorBenefitHistory'],
  },
  {
    url: 'contacts-information',
    tagName: 'ContactsInformation',
    individualInvalidatesTags: ['ContactInformationHistory'],
  },
  {
    url: 'emergency-contacts',
    tagName: 'EmergencyContacts',
    individualInvalidatesTags: ['EmergencyContactHistory'],
  },
  {
    url: 'health-info',
    tagName: 'HealthInfo',
    individualInvalidatesTags: ['HealthInfoHistory'],
  },
  {
    url: 'contracts',
    tagName: 'Contracts',
  },
  {
    url: 'collaborator-drafts',
    tagName: 'CollaboratorDrafts',
  },
  {
    url: 'collaborator-files',
    tagName: 'CollaboratorFiles',
  },
  {
    url: 'auth',
    tagName: 'Auth',
  },
  {
    url: 'roles',
    tagName: 'Roles',
  },
  {
    url: 'user-roles',
    tagName: 'UserRoles',
  },
  {
    url: 'home',
    tagName: 'Home',
  },
  {
    url: 'addenda',
    tagName: 'Addenda',
  },
  {
    url: 'tech-stacks',
    tagName: 'TechStacks',
  },
  {
    url: 'tribes',
    tagName: 'Tribes',
  },
  {
    url: 'positions',
    tagName: 'Positions',
  },
  {
    url: 'contract-terms',
    tagName: 'ContractTerms',
  },
  {
    url: 'currencies',
    tagName: 'Currencies',
  },
  {
    url: 'document-types',
    tagName: 'DocumentTypes',
  },
  {
    url: 'countries',
    tagName: 'Countries',
  },
  {
    url: 'degrees',
    tagName: 'Degrees',
  },
  {
    url: 'phone-codes',
    tagName: 'PhoneCodes',
  },
  {
    url: 'relationships',
    tagName: 'Relationships',
  },
  {
    url: 'visa-license-document-types',
    tagName: 'VisaLicenseDocumentTypes',
  },
  {
    url: 'payment-agreements',
    tagName: 'PaymentAgreements',
  },
  {
    url: 'companies',
    tagName: 'Companies',
  },
  {
    url: 'banks',
    tagName: 'Banks',
  },
  {
    url: 'pension-companies',
    tagName: 'PensionCompanies',
  },
  {
    url: 'insurance-plans',
    tagName: 'InsurancePlans',
  },
  {
    url: 'vaccines',
    tagName: 'Vaccines',
  },
  {
    url: 'cts-institutions',
    tagName: 'CtsInstitutions',
  },
  {
    url: 'collaborator-history',
    tagName: 'CollaboratorHistory',
  },
  {
    url: 'collaborator-child-history',
    tagName: 'CollaboratorChildHistory',
  },
  {
    url: 'contact-information-history',
    tagName: 'ContactInformationHistory',
  },
  {
    url: 'collaborator-benefit-history',
    tagName: 'CollaboratorBenefitHistory',
  },
  {
    url: 'emergency-contact-history',
    tagName: 'EmergencyContactHistory',
  },
  {
    url: 'health-info-history',
    tagName: 'HealthInfoHistory',
  },
  {
    url: 'notifications',
    tagName: 'Notifications',
  },
  {
    url: 'terminations',
    tagName: 'Terminations',
  },
  {
    url: 'termination-reasons',
    tagName: 'TerminationReasons',
  },
  {
    url: 'clients',
    tagName: 'Clients',
  },
  {
    url: 'projects',
    tagName: 'Projects',
  },
  {
    url: 'ceco',
    tagName: 'Cecos',
  },
  {
    url: 'teams',
    tagName: 'Teams',
  },
  {
    url: 'vacations',
    tagName: 'Vacations',
  },
  {
    url: 'leaves',
    tagName: 'Leaves',
  },
  {
    url: 'leave-types',
    tagName: 'LeaveTypes',
  },
  {
    url: 'leave-files',
    tagName: 'LeaveFiles',
  },
  {
    url: 'role-permissions',
    tagName: 'RolePermissions',
  },
  {
    url: 'permissions',
    tagName: 'Permissions',
  },
  {
    url: 'reports',
    tagName: 'Reports',
  },
];

export default urls;
