import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

const RequireStateLocation = ({ component }) => {
  const location = useLocation();
  const { state } = location;

  if (!state) {
    return <Navigate to="/collaborators" state={{ from: location }} replace />;
  }

  return component;
};

RequireStateLocation.propTypes = {
  component: PropTypes.node.isRequired,
};

export default RequireStateLocation;
