/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
  valueModal: '',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state) => {
      state.value = true;
    },
    close: (state) => {
      state.value = false;
    },
    getValueModal: (state, { payload: valueModal }) => {
      state.valueModal = valueModal;
    },
  },
});

export const { open, close, getValueModal } = modalSlice.actions;

export default modalSlice.reducer;
