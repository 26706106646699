/* eslint-disable no-useless-escape */
import { getDate, isFutureDate } from './date';
import { decrypt } from './crypto';

const pattern = {
  str: {
    value: /^[a-zA-Z]+$/,
    message: 'Enter only letters',
  },
  num: {
    value: /^[0-9]*$/,
    message: 'Enter only numbers',
  },
  email: {
    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    message: 'Enter a valid email address',
  },
  tkemail: {
    value: /@tektonlabs.com/,
    message: 'Enter a valid tekton email address',
  },
  alfanum: {
    value: /^[a-z-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.&\d\-_\s]+$/i,
    message: 'Enter only letters and numbers',
  },
  name: {
    value: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
    message: 'Enter only letters',
  },
};

export const getValidateContract = (contracts = []) => {
  const activeContract = contracts.find((contract) => contract.active);
  return activeContract ?? {};
};

export const getFirstContract = (contracts = []) => {
  const firstContract = contracts[contracts.length - 1];
  const startDate = firstContract.payrollStartDate == null
    ? firstContract.startDate : firstContract.payrollStartDate;
  return getDate(startDate, 'PP');
};

const descendingComparator = (a, b, orderBy) => {
  const currentContractA = getValidateContract(a?.contracts);
  const currentContractB = getValidateContract(b?.contracts);
  const salaryA = parseFloat(decrypt(currentContractA[orderBy], 'salary'));
  const salaryB = parseFloat(decrypt(currentContractB[orderBy], 'salary'));

  switch (orderBy) {
    case 'position':
    case 'tribe':
      if (currentContractB[orderBy].name < currentContractA[orderBy].name) {
        return -1;
      }
      if (currentContractB[orderBy].name > currentContractA[orderBy].name) {
        return 1;
      }
      return 0;
    case 'paymentAgreementId':
    case 'currencyId':
      if (currentContractB[orderBy] < currentContractA[orderBy]) {
        return -1;
      }
      if (currentContractB[orderBy] > currentContractA[orderBy]) {
        return 1;
      }
      return 0;
    case 'salary':
      if (salaryB < salaryA) {
        return -1;
      }
      if (salaryB > salaryA) {
        return 1;
      }
      return 0;
    case 'resignation':
      if (b?.termination?.lastDate < a?.termination?.lastDate) {
        return -1;
      }
      if (b?.termination?.lastDate > a?.termination?.lastDate) {
        return 1;
      }
      return 0;
    default:
      break;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const chooseEntity = (item, entity) => {
  let dataModified;
  let name;
  switch (entity) {
    case 'nationality':
      dataModified = {
        value: item?.id,
        label: item?.nationality,
      };
      break;
    case 'phone':
      dataModified = {
        value: item?.id,
        label: `${item?.countryCode} ${item?.code}`,
      };
      break;
    case 'currency':
      dataModified = {
        value: item?.id,
        label: `${item?.code} ${item?.symbol}`,
      };
      break;
    case 'name':
      name = `${item?.firstName} ${item?.lastName}`;
      dataModified = {
        value: item?.id,
        label: name,
      };
      break;
    case 'company':
      dataModified = {
        value: `${item?.id}.id.${item?.countryId}`,
        label: `${item?.name}`,
      };
      break;
    case 'payment-agreement':
      dataModified = {
        value: `${item?.id}.id.${item?.name.split(' ')[0]}`,
        label: `${item?.name}`,
      };
      break;
    case 'contract-terms':
      dataModified = {
        value: `${item?.id}.id.${item?.name.split(' ')[0]}`,
        label: `${item?.name}`,
      };
      break;
    case 'approver':
      name = `${item?.firstName} ${item?.lastName}`;
      dataModified = {
        value: `${item?.id}-c-${name}`,
        label: name,
      };
      break;
    case 'leads':
      name = `${item?.collaborator?.firstName || ''} ${item?.collaborator?.lastName || ''}`;
      dataModified = {
        value: item?.collaborator?.id || 0,
        label: name,
      };
      break;
    case 'department':
      name = `${item.department}`;
      dataModified = {
        value: name,
        label: name,
      };
      break;
    case 'district':
      name = `${item.district}`;
      dataModified = {
        value: name,
        label: name,
      };
      break;
    case 'provinces':
      name = `${item.province}`;
      dataModified = {
        value: name,
        label: name,
      };
      break;
    case 'leave-types':
      dataModified = {
        value: item?.ids || [],
        label: item?.name,
      };
      break;
    default:
      dataModified = {
        value: item?.id || item?.name,
        label: item?.name,
      };
      break;
  }
  return dataModified;
};

export const deletionMessages = {
  position: {
    relation: 'collaborator(s)',
    firstPart: 'Make sure to update',
    lastPart: 'first.',
    permit: 'You can delete this position.',
  },
  entity: {
    relation: 'collaborator(s)',
    firstPart: 'Make sure to update',
    lastPart: 'first.',
    permit: 'You can delete this entity.',
  },
  tribe: {
    relation: 'collaborator(s)',
    firstPart: 'Make sure to update',
    lastPart: 'first.',
    permit: 'You can delete this tribe.',
  },
  CECO: {
    relation: 'tribe(s)',
    firstPart: 'Make sure to reassign',
    lastPart: 'to a different ceco',
    permit: 'You can delete this ceco.',
  },
};

export const stringAvatar = (name = '', styles = {}) => {
  const fullName = name.replace(/\s+/g, '-').trim();
  return {
    sx: {
      ...styles,
    },
    children: `${fullName.split('-')[0][0].toUpperCase()}${fullName.split('-')[1][0].toUpperCase()}`,
  };
};

export const getName = (username) => {
  const fisrtAndLastNameList = username.split('@')[0].split('.');
  const firstName = fisrtAndLastNameList[0];
  const lastName = fisrtAndLastNameList[1];
  const capitalizeFirstName = `${firstName[0].toUpperCase()}${firstName.slice(1, firstName.length)}`;
  const capitalizeLastName = `${lastName[0].toUpperCase()}${lastName.slice(1, lastName.length)}`;
  return `${capitalizeFirstName} ${capitalizeLastName}`;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

export const deleteUnnecessarySpace = (str) => str.replace(/\s+/g, ' ').trim();

export const getDataById = (item, tag) => chooseEntity(item, tag);

export const getDataByEntity = (data, tag, type) => {
  const dataByEntity = data.map((item) => chooseEntity(item, tag));
  if (!type) {
    dataByEntity.unshift({ value: 0, label: '' });
  }
  return dataByEntity;
};

export const getHistoryContracts = (contracts) => contracts.filter((contract) => (
  !contract.active
  && !isFutureDate(contract?.startDate || contract?.payrollStartDate)
));

export const getFutureContract = (contracts) => contracts
  .find((contract) => (
    !contract.active
    && isFutureDate(contract?.startDate || contract?.payrollStartDate)
  ));

export const salaryMask = (value) => {
  const integers = value.split('.')[0];
  const decimals = value.split('.')[1];

  const currentValue = decimals[2];
  const secondDecimal = decimals[1];
  const firstDecimal = decimals[0];

  const firstNumber = integers[0];
  const lastNumber = integers[integers.length - 1];
  const excessIntegers = integers.slice(0, integers.length - 1);

  let newVal = '';
  // Adds new values to the initial state
  if (!Number.isNaN(parseFloat(currentValue))) {
    newVal = `0.${secondDecimal}${currentValue}`;
    if (firstDecimal) {
      newVal = newVal.replace('0.', `${firstDecimal}.`);
      if (firstNumber !== '0') {
        newVal = newVal.replace(
          `${newVal.split('.')[0]}.`,
          `${integers}${firstDecimal}.`,
        );
      }
    }
  }
  // Return initial value when currentValue is string
  if (Number.isNaN(parseFloat(currentValue)) && currentValue !== '') {
    newVal = value.slice(0, value.length - 1);
  }
  // Delete values and return to initial state
  if (!currentValue) {
    newVal = `${excessIntegers}.${lastNumber}${firstDecimal}`;
    if (integers.length < 2) {
      newVal = newVal.replace(`${newVal.split('.')[0]}.`, '0.');
    }
  }

  return newVal;
};

export const getComparator = (order, orderBy) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

export const getGender = (value, description) => {
  switch (value) {
    case 'F':
      return 'Female';
    case 'M':
      return 'Male';
    case 'O':
      return description;
    default:
      return '';
  }
};

export const getRelevantCollabInfo = (collaborator) => {
  const activeContract = getValidateContract(collaborator?.contracts);
  return {
    firstName: collaborator?.firstName,
    lastName: collaborator?.lastName,
    tribe: activeContract?.tribe?.name,
    position: activeContract?.position?.name,
  };
};

export const getValForAutocom = (items, val) => (
  items.find((item) => item.value === val)
);

export const rules = (val) => ({
  required: 'This field is required',
  pattern: { ...pattern[val] },
});

export const getProp = (condition, prop) => {
  if (condition) {
    return { ...prop };
  }
  return {};
};

export const getWorkStatus = (workStatus) => Object.keys(workStatus)
  .filter((e) => workStatus[e]);

export const getCompanyIds = (companyIds, paymentAgreementIds) => {
  if (companyIds.length === 1 && paymentAgreementIds.length > 0) {
    return {};
  }
  if (companyIds.length === 0) return {};
  return { companyIds };
};

export const arrayOfDigits = (amount, obj) => {
  const numOfDigitsArr = [];
  for (let i = 1; i <= amount; i += 1) {
    numOfDigitsArr.push({
      id: i,
      label: obj ? obj[i] : i,
    });
  }
  return numOfDigitsArr;
};

export const statusError = ({ statusCode, message }) => {
  const comment = Array.isArray(message) ? message?.[0] : message;
  switch (statusCode) {
    case 400:
      return {
        type: 'warning',
        title: 'Warning',
        message: comment || 'Check the information registered.',
      };
    case 403:
      return {
        type: 'warning',
        title: 'Forbidden',
        message: comment || 'You do not have access rights to the content. Please, contact the TK Suite super admin user.',
      };
    case 404:
      return {
        type: 'warning',
        title: 'Not Found',
        message: comment || 'The URL is not recognized',
      };
    default:
      return {
        type: 'error',
        title: 'Error',
        message: comment || 'Server error',
      };
  }
};

export const statusSuccess = ({ type, message = '' }) => {
  switch (type) {
    case 'download':
      return {
        type: 'success',
        title: 'Successfully',
        message: 'You have downloaded the file successfully',
      };
    case 'auth':
      return {
        type: 'success',
        title: 'Successfully',
        message: 'You have successfully logged in',
      };
    case 'welcome':
      return {
        type: 'success',
        title: 'Successfully',
        message: 'Welcome to Tk Suite - Admin Panel',
      };
    default:
      return {
        type: 'success',
        title: 'Successfully',
        message: `You have ${type} a ${message} successfully`,
      };
  }
};

export const getCurrencyCodes = (code) => {
  switch (code) {
    case '162':
      return 'PEN,USD';
    case '131':
      return 'MXN,USD';
    default:
      return 'USD';
  }
};

export const isDirtyField = (dirtyFields, collectionFields) => (
  collectionFields.some((field) => dirtyFields[field])
);

export const getFirstNameAndLastName = (firstName, lastName) => {
  if (firstName !== ' ' && lastName !== ' ') return `${firstName.split(' ')[0]} ${lastName.split(' ')[0]}`;
  if (firstName === ' ' && lastName !== ' ') return `N ${lastName.split(' ')[0]}`;
  if (firstName !== ' ' && lastName === ' ') return `${firstName.split(' ')[0]} N`;
  return 'N N';
};

export const getBreadcrumbsText = (pathnames) => pathnames
  .filter((pathname) => Number.isNaN(parseFloat(pathname)))
  .map((pathname, index, arr) => ({
    navNum: index - (arr.length - 1),
    text: pathname.charAt(0).toUpperCase() + pathname.slice(1).replace('-', ' '),
  }));

export const getViewTitle = (pathname) => {
  switch (pathname) {
    case 'cecos':
      return 'CECOs';
    case 'roles-permissions':
      return 'Roles & Permissions';
    case 'tribes':
      return 'Tribes & Teams';
    case 'my-info':
      return 'My Info';
    default:
      return pathname.charAt(0).toUpperCase() + pathname.slice(1);
  }
};

export const getViewHeight = (view) => {
  switch (view) {
    case 'home':
      return {
        container: 'h-60',
        permission: 'h-1/3',
      };
    case 'vacations':
      return {
        container: 'h-80',
        permission: 'h-1/4',
      };
    case 'notifications':
      return {
        container: 'h-60',
        permission: 'h-1/4',
      };
    case 'leaves':
      return {
        container: 'h-48',
        permission: 'h-1/2',
      };
    case 'reports':
      return {
        container: 'h-60',
        permission: 'h-1/5',
      };
    default:
      return {
        container: 'h-28',
        permission: 'h-1/2',
      };
  }
};

export const addDecimalsToSalary = (salary) => {
  if (!salary.toString().includes('.')) {
    return `${salary}.00`;
  }
  return salary;
};

export const getElemOfRange = (firstElem, limit, num) => {
  const elemOfRange = [];

  let n = 0;

  while (n < limit) {
    const nextElem = firstElem + n;
    elemOfRange.push(nextElem);
    n += num;
  }
  return elemOfRange;
};

export const getComments = (hasComments, commentLength) => {
  const noteShort = hasComments ? `${hasComments.substring(0, commentLength)}...` : '--';
  const commentsAll = hasComments || '--';

  return {
    noteShort,
    commentsAll,
  };
};

export const getChildrenProps = (elementList) => elementList
  .reduce((prev, { value }) => (
    {
      ...prev,
      [`childFirstNameId${value}`]: '',
      [`childLastNameId${value}`]: '',
      [`childDocumentTypeId${value}`]: 1,
      [`childDocumentNumberId${value}`]: '',
      [`childBirthDateId${value}`]: '',
    }
  ), {});

export const getSelectedIds = (elementList) => elementList
  .reduce((prev, elem) => {
    const { value } = elem;
    if (Array.isArray(value)) {
      return [...prev, ...value];
    }
    return [...prev, value];
  }, []);

export const getDataByPage = (data, rows) => {
  if (Array.isArray(data)) {
    let numPages;
    const newData = {};
    const arrayForSort = [...data];

    const pages = data.length / rows;

    if (Number.isInteger(pages)) {
      numPages = pages;
    } else {
      numPages = parseInt(pages, 10) + 1;
    }

    for (let index = 0; index < numPages; index += 1) {
      newData[index] = arrayForSort.splice(0, rows);
    }
    return newData;
  }
  return {};
};
