/* eslint-disable prefer-destructuring */
import Home from '../assets/images/home.svg';
import Tribes from '../assets/images/tribes.svg';
import Positions from '../assets/images/positions.svg';
import Collaborators from '../assets/images/collaborators.svg';
import Projects from '../assets/images/projects.svg';
import Clients from '../assets/images/clients.svg';
import CECOs from '../assets/images/CECOs.svg';
import Entities from '../assets/images/entities.svg';
import RolesPermissions from '../assets/images/roles-permissions.svg';
import Reports from '../assets/images/reports.svg';
import Vacations from '../assets/images/vacations.svg';
import Leaves from '../assets/images/leaves.svg';
import MyInfo from '../assets/images/my-info.svg';

const allRoutes = {
  '/home': { route: '/', src: Home, text: 'Home' },
  '/my-info': { route: '/my-info', src: MyInfo, text: 'My Info' },
  '/tribes': { route: '/tribes', src: Tribes, text: 'Tribes & Teams' },
  '/positions': { route: '/positions', src: Positions, text: 'Positions' },
  '/collaborators': { route: '/collaborators', src: Collaborators, text: 'Collaborators' },
  '/projects': { route: '/projects', src: Projects, text: 'Projects' },
  '/clients': { route: '/clients', src: Clients, text: 'Clients' },
  '/cecos': { route: '/cecos', src: CECOs, text: 'CECOs' },
  '/entities': { route: '/entities', src: Entities, text: 'Entities' },
  '/roles-permissions': { route: '/roles-permissions', src: RolesPermissions, text: 'Roles & Permissions' },
  '/reports': { route: '/reports', src: Reports, text: 'Reports' },
  '/vacations': { route: '/vacations', src: Vacations, text: 'Vacations' },
  '/leaves': { route: '/leaves', src: Leaves, text: 'Leaves' },
  '/notifications': { route: '/notifications' },
};

export const createModules = (permissions) => permissions
  .reduce((acc, {
    viewPath,
    code,
  }) => {
    let permission;
    switch (viewPath) {
      case '/tribes':
        permission = code.substring(13, code.length);
        break;
      case '/vacations':
        permission = code.substring(10, code.length);
        break;
      case '/notifications':
        permission = code.substring(14, code.length);
        break;
      default:
        permission = code.split('_')[1];
        break;
    }

    if (acc.some((elem) => elem?.route === viewPath)) {
      const view = acc.find((elem) => elem?.route === viewPath);
      const index = acc.findIndex((elem) => elem?.route === viewPath);
      acc.splice(index, 1, {
        ...view,
        [permission]: true,
      });
      return [...acc];
    }
    return [...acc, { ...allRoutes[viewPath], [permission]: true }];
  }, []);

export const createModulesPermissions = (modules) => modules
  .reduce((acc, module) => {
    const name = module?.route.split('/')[1] || 'home';

    switch (name) {
      case 'home':
        return {
          ...acc,
          [name]: {
            isAll: module?.SUPERADMIN,
            isApprover: module?.APPROVER,
          },
        };
      case 'vacations':
        return {
          ...acc,
          [name]: {
            isAll: module?.SUPERADMIN,
            isApprover: module?.APPROVER_NO_HEAD,
            isApproverHead: module?.APPROVER_HEAD,
          },
        };
      case 'reports':
        return {
          ...acc,
          [name]: {
            isTurnover: module?.TURNOVER,
            isHeadcount: module?.HEADCOUNT,
            isPermanences: module?.PERMANENCES,
            isDemographics: module?.DEMOGRAPHICS,
            isDistribution: module?.DISTR,
          },
        };
      case 'notifications':
        return {
          ...acc,
          [name]: {
            isBirthdays: module?.BIRTHDAYS,
            isAnniversaries: module?.ANNIVERSARIES,
            isEndingContracts: module?.ENDING_CONTRACTS,
            isEndingTrialPeriods: module?.ENDING_TRIAL_PERIODS,
          },
        };
      default:
        return {
          ...acc,
          [name]: {
            isAll: module?.ALL || module?.SUPERADMIN,
          },
        };
    }
  }, {});

export const createNavItem = (modules) => modules
  .reduce((acc, module) => {
    const name = module?.route.split('/')[1] || 'home';

    return {
      ...acc,
      [name]: {
        ...module,
      },
    };
  }, {});
