import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const LeavesPanel = <Loadable callback={() => import('./LeavesPanel')} />;
const LeaveRequest = <Loadable callback={() => import('./LeaveRequest')} />;

const leavesViews = (
  <>
    <Route index element={LeavesPanel} />
    <Route path="leave-request" element={LeaveRequest} />
  </>
);

export default leavesViews;
