import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ component }) => {
  const token = sessionStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return component;
};

RequireAuth.propTypes = {
  component: PropTypes.node.isRequired,
};

export default RequireAuth;
