/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import urls from './urls';

const { REACT_APP_API_URL } = process.env;

const globalInvalidatesTags = [
  'Collaborators',
  'CollaboratorDrafts',
  'CollaboratorFiles',
  'Notifications',
  'Clients',
  'Projects',
  'Cecos',
  'Tribes',
  'Positions',
  'Companies',
  'RolePermissions',
  'Roles',
  'Home',
  'Vacations',
  'Leaves',
  'LeaveFiles',
  'Reports',
  'Currencies'
];

const tksuitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_API_URL}/`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: urls.map(({ tagName }) => tagName),
  endpoints: (build) => urls.reduce((acc, { url, tagName, individualInvalidatesTags = [] }) => {
    const invalidatesTags = [...globalInvalidatesTags, ...individualInvalidatesTags];
    const endpoint = {
      [`getAll${tagName}`]: build.query({
        query: (params) => `${url}${params}`,
        providesTags: [tagName],
      }),
      [`create${tagName}`]: build.mutation({
        query: ({ path, item }) => ({
          url: `${url}${path}`,
          method: 'POST',
          body: item,
        }),
        invalidatesTags,
      }),
      [`getById${tagName}`]: build.query({
        query: (id) => `${url}/${id}`,
        providesTags: [tagName],
      }),
      [`updateById${tagName}`]: build.mutation({
        query: ({ id, ...rest }) => ({
          url: `${url}/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags,
      }),
      [`deleteById${tagName}`]: build.mutation({
        query: (id) => ({
          url: `${url}/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags,
      }),
      [`updateByPath${tagName}`]: build.mutation({
        query: ({ path, item }) => ({
          url: `${url}/${path.subPath}/${path.id}`,
          method: 'PATCH',
          body: item,
        }),
        invalidatesTags,
      }),
    };
    return Object.assign(acc, endpoint);
  }, {}),
});

export default tksuitApi;
