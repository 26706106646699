import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const PositionsPanel = <Loadable callback={() => import('./PositionsPanel')} />;

const positionsViews = (
  <Route index element={PositionsPanel} />
);

export default positionsViews;
