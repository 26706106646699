/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  file: {
    url: '',
    name: '',
    type: '',
  },
};

export const uploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {
    getFile: (state, { payload: file }) => {
      state.file = file;
    },
  },
});

export const { getFile, getPathId } = uploadFileSlice.actions;

export default uploadFileSlice.reducer;
