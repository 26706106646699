import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { open, getAlert } from '../app/feature/Alert/alertSlice';
import { setUserInfo } from '../app/feature/Auth/authSlice';
import { statusError, statusSuccess } from '../utils/general';
import { createModules, createModulesPermissions } from '../utils/permissions';
import { getModulesPermissions } from '../app/feature/permissionSlice';
import tksuitApi from '../services';

const useModules = () => {
  const dispatch = useDispatch();
  const [modules, setModules] = useState([]);

  const { useCreateAuthMutation } = tksuitApi;
  const [createAuth, { isLoading: isCreateAuthLoading }] = useCreateAuthMutation();
  const [isLoading, setLoading] = useState(true);

  const responseError = (err) => {
    dispatch(getAlert(statusError({ ...err?.data })));
    dispatch(open());
  };

  const responseSuccess = (resp, obj) => {
    const app = resp?.roles.find((role) => role?.app === 'Brain');
    const { role, permissions } = app;
    const permissionsByRoles = [
      ...permissions,
      {
        id: 'my-info',
        code: 'INFO_ALL',
        viewPath: '/my-info',
      },
    ];
    if (role === 'SUPER ADMIN') {
      permissionsByRoles.push({
        id: 'roles',
        code: 'PERMISSIONS_ALL',
        viewPath: '/roles-permissions',
      });
    }
    const modulesAll = createModules(permissionsByRoles);

    const mainModules = modulesAll
      .filter(({ route }) => route !== '/notifications');
    setModules(mainModules);

    dispatch(getModulesPermissions({
      ...createModulesPermissions(modulesAll),
    }));
    dispatch(setUserInfo({
      user: {
        googleCalendarId: resp?.collaborator?.vacationRegimen?.googleCalendarId,
        collaboratorId: resp?.collaborator?.id,
      },
    }));
    dispatch(getAlert(statusSuccess(obj)));
    dispatch(open());
  };

  const getRole = async (tokenId) => {
    if (!isCreateAuthLoading) {
      try {
        const auth = {
          path: '/user-info',
          item: { tokenId },
        };
        const resp = await createAuth(auth).unwrap();
        responseSuccess(resp, { type: 'welcome' });
      } catch (err) {
        responseError(err);
      }
    }
  };

  useLayoutEffect(() => {
    const tokenId = sessionStorage.getItem('token');
    let mounted = true;
    if (tokenId) {
      getRole(tokenId).then(() => {
        if (mounted) {
          setLoading(false);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  return { modules, isLoading };
};

export default useModules;
