/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
  alert: {},
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    open: (state) => {
      state.value = true;
    },
    close: (state) => {
      state.value = false;
    },
    getAlert: (state, { payload: alert }) => {
      state.alert = alert;
    },
  },
});

export const {
  open,
  close,
  getAlert,
} = alertSlice.actions;

export default alertSlice.reducer;
