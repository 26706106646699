/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { defaultFilters, defaultTagsFilters } from '../../schemas/collaborators/formSchema';

const initialState = {
  valSearch: '',
  workStatus: {
    ...defaultTagsFilters,
  },
  filters: {
    ...defaultFilters,
  },
  isTagsFilter: true,
  isFilter: true,
  order: 'asc',
  orderBy: 'resignation',
  page: 0,
};

export const collabFiltersSlice = createSlice({
  name: 'collabFilters',
  initialState,
  reducers: {
    getValSearch: (state, { payload: valSearch }) => {
      state.valSearch = valSearch;
    },
    getTagsFilters: (state, { payload: workStatus }) => {
      state.workStatus = workStatus;
    },
    getFilters: (state, { payload: filters }) => {
      state.filters = filters;
    },
    setIsTagsFilter: (state, { payload: isTagsFilter }) => {
      state.isTagsFilter = isTagsFilter;
    },
    setIsFilter: (state, { payload: isFilter }) => {
      state.isFilter = isFilter;
    },
    setOrder: (state, { payload: order }) => {
      state.order = order;
    },
    setOrderBy: (state, { payload: orderBy }) => {
      state.orderBy = orderBy;
    },
    setPage: (state, { payload: page }) => {
      state.page = page;
    },
  },
});

export const {
  getValSearch,
  getTagsFilters,
  getFilters,
  setIsTagsFilter,
  setIsFilter,
  setPage,
  setOrder,
  setOrderBy,
} = collabFiltersSlice.actions;

export default collabFiltersSlice.reducer;
