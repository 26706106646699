import CryptoJS from 'crypto-js';
import { decryptionProperties } from '../collections/collaborators';

const {
  REACT_APP_PRIVATE_KEY,
} = process.env;

export const encrypt = (data) => {
  if (data && typeof data === 'string') {
    const encrypted = CryptoJS.AES.encrypt(data, REACT_APP_PRIVATE_KEY).toString();
    return encrypted;
  }
  return data;
};

export const decrypt = (data, field) => {
  if (data && decryptionProperties.some((prop) => prop === field)) {
    const bytes = CryptoJS.AES.decrypt(data, REACT_APP_PRIVATE_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
  return data;
};
