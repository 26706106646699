import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loadable from './components/complex/Loadable';
import RequireAuth from './required/RequireAuth';
import RequirePermission from './required/RequirePermission';
import collaboratorsViews from './screens/Collaborators/views';
import myInfoViews from './screens/MyInfo/views';
import clientsViews from './screens/Clients/views';
import projectsViews from './screens/Projects/views';
import cecosViews from './screens/CECOs/views';
import tribesViews from './screens/Tribes/views';
import positionsViews from './screens/Positions/views';
import entitiesViews from './screens/Entities/views';
import vacationsViews from './screens/Vacations/views';
import leavesViews from './screens/Leaves/views';
import permissionsViews from './screens/RolesAndPermissions/views';
import reportsViews from './screens/Reports/views';

const Auth = <Loadable callback={() => import('./feature/Auth')} />;
const Login = <Loadable callback={() => import('./feature/Auth/Login')} />;
const Sidebar = <Loadable callback={() => import('./components/complex/Sidebar')} />;
const Home = <Loadable callback={() => import('./screens/Home')} />;
const MyInfo = <Loadable callback={() => import('./screens/MyInfo')} />;
const Collaborators = <Loadable callback={() => import('./screens/Collaborators')} />;
const Clients = <Loadable callback={() => import('./screens/Clients')} />;
const Projects = <Loadable callback={() => import('./screens/Projects')} />;
const CECOs = <Loadable callback={() => import('./screens/CECOs')} />;
const Tribes = <Loadable callback={() => import('./screens/Tribes')} />;
const Positions = <Loadable callback={() => import('./screens/Positions')} />;
const Entities = <Loadable callback={() => import('./screens/Entities')} />;
const RolesAndPermissions = <Loadable callback={() => import('./screens/RolesAndPermissions')} />;
const Reports = <Loadable callback={() => import('./screens/Reports')} />;
const Vacations = <Loadable callback={() => import('./screens/Vacations')} />;
const Leaves = <Loadable callback={() => import('./screens/Leaves')} />;
const NoMatch = <Loadable callback={() => import('./screens/NoMatch')} />;

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={Auth}>
        <Route
          index
          element={Login}
        />
      </Route>
      <Route
        path="/"
        element={(<RequireAuth component={Sidebar} />)}
      >
        <Route index element={<RequirePermission component={Home} />} />
        <Route path="my-info" element={<RequirePermission component={MyInfo} />}>
          {myInfoViews}
        </Route>
        <Route path="tribes" element={<RequirePermission component={Tribes} />}>
          {tribesViews}
        </Route>
        <Route path="positions" element={<RequirePermission component={Positions} />}>
          {positionsViews}
        </Route>
        <Route path="collaborators" element={<RequirePermission component={Collaborators} />}>
          {collaboratorsViews}
        </Route>
        <Route path="projects" element={<RequirePermission component={Projects} />}>
          {projectsViews}
        </Route>
        <Route path="clients" element={<RequirePermission component={Clients} />}>
          {clientsViews}
        </Route>
        <Route path="cecos" element={<RequirePermission component={CECOs} />}>
          {cecosViews}
        </Route>
        <Route path="entities" element={<RequirePermission component={Entities} />}>
          {entitiesViews}
        </Route>
        <Route path="vacations" element={<RequirePermission component={Vacations} />}>
          {vacationsViews}
        </Route>
        <Route path="leaves" element={<RequirePermission component={Leaves} />}>
          {leavesViews}
        </Route>
        <Route path="roles-permissions" element={<RequirePermission component={RolesAndPermissions} />}>
          {permissionsViews}
        </Route>
        <Route
          path="reports"
          element={<RequirePermission component={Reports} />}
        >
          {reportsViews}
        </Route>
        <Route path="*" element={NoMatch} />
      </Route>
    </Routes>
  </Router>
);

export default App;
