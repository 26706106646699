/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  image: {
    url: '',
    name: '',
    type: '',
  },
};

export const uploadImgSlice = createSlice({
  name: 'uploadImg',
  initialState,
  reducers: {
    getImage: (state, { payload: image }) => {
      state.image = image;
    },
  },
});

export const { getImage } = uploadImgSlice.actions;

export default uploadImgSlice.reducer;
