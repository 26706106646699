import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const ProjectsPanel = <Loadable callback={() => import('./ProjectsPanel')} />;

const projectsViews = (
  <Route index element={ProjectsPanel} />
);

export default projectsViews;
