import React from 'react';

const NoMatch = () => (
  <div className="w-full pt-2 px-8">
    <h2 className="text-center text-lg text-teal-green">You do not have access to this module</h2>
    <p className="text-center text-sm font-medium">
      Make sure that the module exists in this project.
      If it does, ask a site administrator for permission.
    </p>
  </div>
);

export default NoMatch;
