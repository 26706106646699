export const personalCollection = [
  'firstName',
  'secondName',
  'firstLastName',
  'secondLastName',
  'documentTypeId',
  'gender',
  'genderDescription',
  'nationalityId',
  'passportId',
  'visaLicenseDocumentTypeId',
  'degreeId',
  'vacationRegimenId',
  'status',
  'documentNumber',
  'birthDate',
  'hasValidPassport',
  'hasValidVisaLicense',
];

export const contractCollection = [
  'companyId',
  'isExternal',
  'paymentAgreementId',
  'contractTermId',
  'startDate',
  'currencyId',
  'salary',
  'tribeId',
  'endDate',
  'level',
  'positionId',
  'paymentTypeId',
];

export const benefitCollection = [
  'paymentTypeId',
  'bankId',
  'bankDescription',
  'currencyBankId',
  'bankAccount',
  'cciBankAccount',
  'swiftCode',
  'bankAddress',
  'ctsBankId',
  'ctsCurrencyId',
  'ctsBankAccount',
  'cciCtsBankAccount',
  'insurancePlanId',
  'pensionCompanyId',
];

export const contactInfoCollection = [
  'personalEmail',
  'workEmail',
  'phoneCodeId',
  'phoneNumber',
  'countryId',
  'city',
  'state',
  'district',
  'address',
  'addressReference',
];

export const emergencyContactCollection = [
  'contactNameId1',
  'relationshipId1',
  'phoneCodeEmergencyId1',
  'phoneNumberEmergencyId1',
  'contactNameId2',
  'relationshipId2',
  'phoneCodeEmergencyId2',
  'phoneNumberEmergencyId2',
];

export const labelDoses = {
  1: 'First dose',
  2: 'Second dose',
  3: 'Third dose',
  4: 'Fourth dose',
  5: 'Fifth dose',
  6: 'Sixth dose',
  7: 'Seventh dose',
  8: 'Eighth dose',
  9: 'Ninth dose',
  10: 'Tenth dose',
};

export const labelEmergency = {
  0: 'First Contact Name',
  1: 'Second Contact Name',
};

export const decryptionProperties = [
  'document_number',
  'passport_id',
  'phone_number',
  'personal_email',
  'city',
  'state',
  'district',
  'address',
  'address_reference',
  'salary',
  'bank_account',
  'swift_code',
  'bank_address',
  'bank_description',
  'cci_bank_account',
  'cts_bank_account',
  'cci_cts_bank_account',
  'contact_name',
  'comments',
];

export const trialPeriod = [
  {
    value: 3,
    label: '3 months',
  },
  {
    value: 6,
    label: '6 months',
  },
];

export const tabsDetail = [
  { value: '1', label: 'Contact Information' },
  { value: '2', label: 'Payment Information' },
  { value: '3', label: 'Contract Information' },
  { value: '4', label: 'Health Information' },
  { value: '5', label: 'Roles Assignment' },
  { value: '6', label: 'Stacks Information' },
  { value: '7', label: 'Documents' },
  { value: '8', label: 'Last Updates' },
];

export const tabsDetailMyInfo = [
  { value: '1', label: 'Contact Information' },
  { value: '2', label: 'Payment Information' },
  { value: '3', label: 'Contract Information' },
  { value: '4', label: 'Health Information' },
  { value: '5', label: 'Roles Assignment' },
  { value: '6', label: 'Stacks Information' },
  { value: '7', label: 'Documents' },
];

export const tags = {
  1: {
    id: '1',
    text: 'Regulars',
    color: 'grey-60',
    hex: '#E0E0E0',
  },
  2: {
    id: '2',
    text: 'Scheduled changes',
    color: 'purple-magenta',
    hex: '#9C27B0',
  },
  3: {
    id: '3',
    text: 'Changes',
    color: 'yellow-sun',
    hex: '#FD9924',
  },
  4: {
    id: '4',
    text: 'New collaborator',
    color: 'dodger-blue',
    hex: '#488DFF',
  },
  5: {
    id: '5',
    text: 'Coming out this month',
    color: 'coral-red',
    hex: '#F25252',
  },
};

export const numberList = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
];

export const childrenCollection = [
  'numberOfChildren',
  'hasChildren',
  ...(numberList
    .reduce((prev, { value }) => (
      [
        ...prev,
        `childNameId${value}`,
        `childDocumentTypeId${value}`,
        `childDocumentNumberId${value}`,
        `childBirthDateId${value}`,
      ]
    ), [])),
];

export const groupOfTribes = [
  {
    value: 1,
    label: 'Core',
  },
  {
    value: 2,
    label: 'Support',
  },
  {
    value: 3,
    label: 'Sales & Marketing',
  },
];

export const children = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

export const levels = [
  {
    value: '1',
    label: 'Level 1',
  },
  {
    value: '2',
    label: 'Level 2',
  },
  {
    value: '3',
    label: 'Level 3',
  },
];
