import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SkeletonTable = ({ nCols, nRows, hasHeader }) => {
  const cols = Array(nCols * nRows).fill(null).map((_, i) => i);
  return (

    <Grid container spacing={2} columns={nCols} mb={4}>
      {hasHeader && (
      <Grid item xs={nCols}>
        <Typography variant="h2">
          <Skeleton />
        </Typography>
      </Grid>
      )}
      {cols.map((c) => (
        <Grid xs={1} item key={`grid-${c}`}>
          <Skeleton />
        </Grid>
      ))}
    </Grid>
  );
};
SkeletonTable.propTypes = {
  nCols: PropTypes.number.isRequired,
  nRows: PropTypes.number,
  hasHeader: PropTypes.bool,
};
SkeletonTable.defaultProps = {
  nRows: 3,
  hasHeader: false,
};
export default SkeletonTable;
