import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const LinearIndeterminate = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '999',
      width: '100%',
    }}
  >
    <LinearProgress />
  </Box>
);

export default LinearIndeterminate;
