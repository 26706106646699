/* eslint-disable quote-props */
import {
  deleteUnnecessarySpace,
  getComparator,
  getElemOfRange,
  getValidateContract,
  getChildrenProps,
} from '../../utils/general';
import { getDate } from '../../utils/date';
import { decrypt } from '../../utils/crypto';
import { numberList } from '../../collections/collaborators';

export const terminationSchema = {
  lastDate: '',
  terminationReasonId: '',
  comments: '',
};

export const contactInfoSchema = ({
  id,
  personalEmail,
  workEmail,
  phoneCodeId,
  phoneNumber,
  countryId,
  city,
  state,
  district,
  address,
  addressReference,
}) => ({
  idContact: id,
  personalEmail: decrypt(personalEmail, 'personal_email'),
  workEmail,
  phoneCodeId,
  phoneNumber: decrypt(phoneNumber, 'phone_number'),
  countryId,
  city: decrypt(city, 'city'),
  state: decrypt(state, 'state'),
  district: decrypt(district, 'district'),
  address: decrypt(address, 'address'),
  addressReference: decrypt(addressReference, 'address_reference'),
});

export const contractsSchema = ({
  id,
  company,
  isExternal,
  paymentAgreement,
  contractTerm,
  startDate,
  payrollStartDate,
  paymentMode,
  currencyId,
  salary,
  endDate,
  tribeId,
  positionId,
  level,
  trialPeriod,
}) => {
  let companyId;
  let paymentAgreementId;
  let contractTermId;

  if (paymentAgreement?.id && contractTerm?.id && company?.id) {
    const paymentAgreementValue = paymentAgreement?.name.split(' ')[0];
    const contractTermValue = contractTerm?.name.split(' ')[0];

    companyId = `${company?.id}.id.${company?.countryId}`;
    paymentAgreementId = `${paymentAgreement?.id}.id.${paymentAgreementValue}`;
    contractTermId = `${contractTerm?.id}.id.${contractTermValue}`;
  } else {
    companyId = '1.id.162';
    paymentAgreementId = '3.id.Payroll';
    contractTermId = '10.id.Indeterminate';
  }

  return {
    idContract: id,
    companyId,
    isExternal: isExternal ? 'true' : 'false',
    paymentAgreementId,
    contractTermId,
    startDate: getDate(startDate || payrollStartDate, 'PP'),
    paymentMode,
    currencyId: currencyId || 1,
    salary: salary ? decrypt(salary, 'salary') : '0.00',
    tribeId: tribeId || 0,
    positionId: positionId || 0,
    level: `${level}` || '',
    endDate: getDate(endDate, 'PP'),
    trialPeriod: trialPeriod || 0,
  };
};

export const addendaSchema = ({
  id,
  tribeId,
  positionId,
  level,
  currencyId,
  salary,
  contractTerm,
}) => ({
  id,
  tribeId,
  positionId,
  level: `${level}` || '',
  currencyId,
  salary: decrypt(salary, 'salary'),
  startDate: '',
  contractTerm: `${contractTerm?.name.split(' ')[0]}`,
});

export const stackSchema = ({
  appliesStack,
  stack,
}) => ({
  isApply: appliesStack ? 'true' : 'false',
  profiles: stack?.profiles || [],
  primaryStack: stack?.primaryStack || [],
  secondaryStack: stack?.secondaryStack || [],
});

export const benefitSchema = ({
  id,
  paymentTypeId,
  bankId,
  bankDescription,
  currencyId,
  bankAccount,
  cciBankAccount,
  swiftCode,
  bankAddress,
  ctsBankId,
  ctsCurrencyId,
  ctsBankAccount,
  cciCtsBankAccount,
  insurancePlanId,
  pensionCompanyId,
}) => ({
  idBenefit: id,
  paymentTypeId: paymentTypeId === 2 ? 'true' : 'false',
  bankId: bankId || 0,
  bankDescription: decrypt(bankDescription, 'bank_description'),
  currencyBankId: currencyId || 0,
  bankAccount: decrypt(bankAccount, 'bank_account'),
  cciBankAccount: decrypt(cciBankAccount, 'cci_bank_account'),
  swiftCode: decrypt(swiftCode, 'swift_code'),
  bankAddress: decrypt(bankAddress, 'bank_address'),
  ctsBankId: ctsBankId || 0,
  ctsCurrencyId: ctsCurrencyId || 0,
  ctsBankAccount: decrypt(ctsBankAccount, 'cts_bank_account'),
  cciCtsBankAccount: decrypt(cciCtsBankAccount, 'cci_cts_bank_account'),
  insurancePlanId: insurancePlanId || 0,
  pensionCompanyId: pensionCompanyId || 0,
});

export const emergencyContactSchema = (emergencyContacts) => ({
  ...(emergencyContacts
    .slice()
    .sort(getComparator('asc', 'id'))
    .reduce((
      prev,
      {
        id,
        contactName,
        relationshipId,
        phoneCodeId: phoneCodeEmergencyId,
        phoneNumber: phoneNumberEmergency,
      },
      index,
    ) => {
      // It's necessary to know whether it's the first or second emergency contact.
      const emgContactNum = index + 1;
      return {
        ...prev,
        contactNameId2: '',
        relationshipId2: 0,
        phoneCodeEmergencyId2: 174,
        phoneNumberEmergencyId2: '',
        [`id${emgContactNum}`]: id,
        [`contactNameId${emgContactNum}`]: decrypt(contactName, 'contact_name'),
        [`relationshipId${emgContactNum}`]: relationshipId,
        [`phoneCodeEmergencyId${emgContactNum}`]: phoneCodeEmergencyId,
        [`phoneNumberEmergencyId${emgContactNum}`]: decrypt(phoneNumberEmergency, 'phone_number'),
      };
    }, {})),
});

export const healthInfoSchema = ({
  id,
  allergies,
  isVaccinated,
  covidInfo,
  numberOfDoses,
}) => ({
  idHealth: id,
  isVaccinated: isVaccinated ? 'true' : 'false',
  allergies,
  ...({
    vaccineId1: 0,
    vaccineId2: 0,
    vaccineId3: 0,
    vaccineId4: 0,
    vaccineId5: 0,
    vaccineId6: 0,
    vaccineId7: 0,
    vaccineId8: 0,
    vaccineId9: 0,
    vaccineId10: 0,
    ...(covidInfo && covidInfo.reduce((prev, curr, index) => (
      {
        ...prev,
        [`vaccineId${index + 1}`]: curr.vaccineId,
      }
    ), {})),
  }),
  numberOfDoses: numberOfDoses || 1,
});

export const rolesSchema = (roles) => (roles.reduce((prev, curr) => (
  {
    ...prev,
    [`appId${curr.appId}`]: curr.appId,
    [`roleId${curr.appId}`]: curr.roleId,
    [`id${curr.appId}`]: curr.id,
  }
), {}));

export const childrenInfoSchema = ({
  numberOfChildren,
  children,
}) => ({
  hasChildren: numberOfChildren > 0 ? 'true' : 'false',
  numberOfChildren: numberOfChildren || 1,
  ...(getChildrenProps(numberList)),
  ...(getElemOfRange(0, numberOfChildren, 1)
    .slice()
    .sort(getComparator('asc', 'id'))
    .reduce((prev, value, index) => {
      const childNumber = value + 1;
      const childInfo = children?.[value];
      return {
        ...prev,
        [`childrenId${childNumber}`]: childInfo?.id || index,
        [`childFirstNameId${childNumber}`]: childInfo?.firstName || '',
        [`childLastNameId${childNumber}`]: childInfo?.lastName || '',
        [`childDocumentTypeId${childNumber}`]: childInfo?.documentTypeId || 1,
        [`childDocumentNumberId${childNumber}`]: decrypt(childInfo?.documentNumber, 'document_number') || '',
        [`childBirthDateId${childNumber}`]: childInfo?.birthDate || '',
      };
    }, {})),
});

export const collaboratorSchema = ({
  isEdit,
  firstName,
  lastName,
  documentTypeId,
  documentNumber,
  birthDate,
  gender,
  genderDescription,
  nationalityId,
  numberOfChildren,
  children,
  passportId,
  hasValidVisaLicense,
  visaLicenseDocumentTypeId,
  degreeId,
  vacationRegimenId,
  status,
  workStatus,
  imagePath,
  originalImageName,
  contactInformation,
  contracts,
  contract,
  benefit,
  emergencyContacts,
  healthInfo,
  appliesStack,
  user,
  roles,
  ...rest
}) => ({
  isEdit,
  firstName: deleteUnnecessarySpace(firstName).split(' ')[0],
  secondName: deleteUnnecessarySpace(firstName).split(' ')[1],
  firstLastName: deleteUnnecessarySpace(lastName).split(' ')[0],
  secondLastName: deleteUnnecessarySpace(lastName).split(' ')[1],
  documentTypeId,
  gender,
  genderDescription,
  nationalityId,
  passportId: decrypt(passportId, 'passport_id'),
  visaLicenseDocumentTypeId: visaLicenseDocumentTypeId ?? 0,
  degreeId,
  vacationRegimenId,
  status,
  workStatus,
  documentNumber: decrypt(documentNumber, 'document_number'),
  birthDate: getDate(birthDate, 'PP'),
  hasValidPassport: decrypt(passportId, 'passport_id') ? 'true' : 'false',
  hasValidVisaLicense: hasValidVisaLicense?.toString(),
  imagePath,
  originalImageName,
  ...childrenInfoSchema({ numberOfChildren, children } || {}),
  ...contactInfoSchema(contactInformation || {}),
  ...contractsSchema(contracts ? getValidateContract(contracts) : contract || {}),
  ...stackSchema({ appliesStack, stack: rest?.techStack }),
  ...benefitSchema(benefit || {}),
  ...emergencyContactSchema(emergencyContacts || {}),
  ...healthInfoSchema(healthInfo || {}),
  ...rolesSchema(user ? user?.roles : roles || []),
});

export const defaultValues = {
  firstName: '',
  secondName: '',
  firstLastName: '',
  secondLastName: '',
  documentTypeId: 1,
  documentNumber: '',
  birthDate: '',
  gender: 'M',
  genderDescription: '',
  nationalityId: 0,
  hasChildren: 'false',
  numberOfChildren: 1,
  ...(getChildrenProps(numberList)),
  hasValidPassport: 'false',
  passportId: '',
  hasValidVisaLicense: 'false',
  visaLicenseDocumentTypeId: 0,
  degreeId: 0,
  vacationRegimenId: 0,
  personalEmail: '',
  workEmail: '',
  phoneCodeId: 174,
  phoneNumber: '',
  countryId: 0,
  city: '',
  state: '',
  district: '',
  address: '',
  addressReference: '',
  companyId: '1.id.162',
  isExternal: 'false',
  paymentAgreementId: '3.id.Payroll',
  contractTermId: '10.id.Indeterminate',
  startDate: '',
  currencyId: 1,
  salary: '0.00',
  tribeId: 0,
  endDate: '',
  positionId: 0,
  level: '',
  trialPeriod: 0,
  isApply: 'true',
  profiles: [],
  primaryStack: [],
  secondaryStack: [],
  paymentTypeId: 'true',
  bankId: 0,
  bankDescription: '',
  currencyBankId: 0,
  bankAccount: '',
  cciBankAccount: '',
  swiftCode: '',
  bankAddress: '',
  ctsBankId: 0,
  ctsCurrencyId: 0,
  ctsBankAccount: '',
  cciCtsBankAccount: '',
  insurancePlanId: 0,
  pensionCompanyId: 0,
  isEdit: false,
  contactNameId1: '',
  relationshipId1: 0,
  phoneCodeEmergencyId1: 174,
  phoneNumberEmergencyId1: '',
  contactNameId2: '',
  relationshipId2: 0,
  phoneCodeEmergencyId2: 174,
  phoneNumberEmergencyId2: '',
  isVaccinated: 'true',
  vaccineId1: 0,
  vaccineId2: 0,
  vaccineId3: 0,
  vaccineId4: 0,
  vaccineId5: 0,
  vaccineId6: 0,
  vaccineId7: 0,
  vaccineId8: 0,
  vaccineId9: 0,
  vaccineId10: 0,
  numberOfDoses: 1,
  allergies: '',
  roleId1: 0,
  roleId2: 0,
  roleId3: 0,
  roleId4: 0,
};

export const defaultContractsAnBenefitSchema = {
  companyId: '1.id.162',
  isExternal: 'false',
  paymentAgreementId: '3.id.Payroll',
  contractTermId: '10.id.Indeterminate',
  startDate: '',
  currencyId: 1,
  salary: '0.00',
  tribeId: 0,
  positionId: 0,
  level: '',
  endDate: '',
  paymentTypeId: 'true',
  bankId: 0,
  bankDescription: '',
  currencyBankId: 0,
  bankAccount: '',
  cciBankAccount: '',
  swiftCode: '',
  bankAddress: '',
  ctsBankId: 0,
  ctsCurrencyId: 0,
  ctsBankAccount: '',
  cciCtsBankAccount: '',
  insurancePlanId: 0,
  pensionCompanyId: 0,
};

export const defaultTagsFilters = {
  '0': false,
  '1': false,
  '2': false,
  '3': false,
  '4': false,
  '5': false,
};

export const defaultFilters = {
  groupOfTribes: [],
  tribeGroups: [],
  tribes: [],
  tribeIds: [],
  companies: [],
  companyIds: [],
  currencies: [],
  currencyIds: [],
  agreementType: [],
  paymentAgreementIds: [],
  cecos: [],
  profiles: [],
  primaryStack: [],
  secondaryStack: [],
  cecoIds: [],
  birthdateMonth: '',
  isChildren: '',
  startDate: '',
  endDate: '',
};
