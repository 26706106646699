import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from '../../../components/complex/Loadable';

const VacationsPanel = <Loadable callback={() => import('./VacationsPanel')} />;
const BookVacations = <Loadable callback={() => import('./BookVacations')} />;

const vacationViews = (
  <>
    <Route index element={VacationsPanel} />
    <Route path="book-vacations" element={BookVacations} />
  </>
);

export default vacationViews;
