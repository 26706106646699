/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
  isAll with value true is when user has super admin permissions
  isAll with value false is when user has other role permissions
  isApprover with value true is when user has approver permissions
*/
const initialState = {
  modules: {
    home: {
      isAll: false,
      isApprover: false,
    },
    collaborators: {
      isAll: false,
    },
    tribes: {
      isAll: false,
    },
    positions: {
      isAll: false,
    },
    projects: {
      isAll: false,
    },
    clients: {
      isAll: false,
    },
    cecos: {
      isAll: false,
    },
    entities: {
      isAll: false,
    },
    leaves: {
      isAll: false,
    },
    'roles-permissions': {
      isAll: false,
    },
    reports: {
      isTurnover: false,
      isHeadcount: false,
      isPermanences: false,
      isDemographics: false,
      isDistribution: false,
    },
    vacations: {
      isAll: false,
      isApprover: false,
      isApproverHead: false,
    },
    notifications: {
      isBirthdays: false,
      isAnniversaries: false,
      isEndingContracts: false,
      isEndingTrialPeriods: false,
    },
  },
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    getModulesPermissions: (state, { payload: modules }) => {
      state.modules = modules;
    },
  },
});

export const { getModulesPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;
